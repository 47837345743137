:root {
  --plyr-color-main: var(--chakra-colors-whiteAlpha-500);
  --plyr-video-control-color: var(--chakra-colors-whiteAlpha-900);
  --plyr-video-control-color-hover: var(--chakra-colors-green-400);
  --plyr-control-toggle-checked-background: var(--chakra-colors-primary-500);
  --plyr-video-control-background-hover: var(--chakra-colors-gray-100);
  --plyr-control-icon-size: 1.25rem;
  /* CSS Vars: https://vidstack.io/docs/player/components/layouts/plyr-layout#css-variables */
}

.plyr__menu__container .plyr__control[role='menuitemradio']:focus-visible::before, .plyr__menu__container .plyr__control[role='menuitemradio'][data-focus]::before, .plyr__menu__container .plyr__control[role='menuitemradio']:hover::before {
  background-color: var(--chakra-colors-primary-500) !important;
}

.plyr__control--overlaid.plyr__control[data-plyr='play'] svg {
  fill: var(--plyr-video-control-color-hover) !important;
  --plyr-control-icon-size: 6rem;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.8);
}